import { render, staticRenderFns } from "./EditResource.vue?vue&type=template&id=479bf0da&scoped=true&"
import script from "./EditResource.vue?vue&type=script&lang=js&"
export * from "./EditResource.vue?vue&type=script&lang=js&"
import style0 from "./EditResource.vue?vue&type=style&index=0&id=479bf0da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479bf0da",
  null
  
)

export default component.exports