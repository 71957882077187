<template>
  <div class="new-resource fade-up">
    <div v-if="isError">Топилмади!</div>
    <el-row v-else :gutter="30" style="position: relative;">
      <el-col :span="16">
        <el-card v-loading="langLoading" class="resource-content-card">
          <div class="card-header" slot="header">
            <div class="lang-section">
              <h3>Тилни танланг:</h3>
              <el-radio-group v-model="selectedLang">
                <el-radio-button label="oz">O'zbekcha</el-radio-button>
                <el-radio-button label="uz">Узбекча</el-radio-button>
                <el-radio-button label="ru">Русский</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div
            class="title-section"
            :class="{
              'invalid-error': $v.form[`title_${selectedLang}`].$error
            }"
          >
            <h3>Номи</h3>
            <el-input
              size="large"
              placeholder="Номини киритинг"
              v-model="$v.form[`title_${selectedLang}`].$model"
            ></el-input>
          </div>
          <div class="desc-section">
            <div class="desc-row">
              <h3>Қисқа тавсиф</h3>
              <small
                >{{ form[`description_${selectedLang}`].length }}/600</small
              >
            </div>
            <el-input
              size="large"
              type="textarea"
              maxlength="600"
              placeholder="Тавсифни киритинг"
              v-model="form[`description_${selectedLang}`]"
            ></el-input>
          </div>
          <div class="content-section">
            <h3>Контент</h3>
            <quill-editor
              class="editor"
              v-model="form[`content_${selectedLang}`]"
            />
          </div>
          <!-- <div class="hashtag-section">
            <h3>Хештеглар:</h3>
            <el-select
              v-model="selectedHashtags"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="Хештег танланг"
            >
              <el-option
                v-for="tag in hashtags"
                :key="tag.value"
                :label="tag.label"
                :value="tag.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </el-card>
      </el-col>
      <el-col :span="8" style="position: sticky; top: 0">
        <el-card>
          <div
            class="date-section"
            :class="{
              'invalid-error': $v.form.publish_at.$error
            }"
          >
            <h4>Нашр этиш вақти</h4>
            <el-date-picker
              v-model="$v.form.publish_at.$model"
              type="datetime"
              placeholder="Санани танланг"
              class="date-input"
            >
            </el-date-picker>
          </div>
          <div
            class="category-section"
            :class="{
              'invalid-error': $v.form.category.$error
            }"
          >
            <h4>Категория</h4>
            <el-select
              v-model="$v.form.category.$model"
              placeholder="Категория танланг"
            >
              <el-option
                v-for="cat in categories"
                :key="cat.id"
                :value="cat.id"
                :label="cat.name_uz"
              ></el-option>
            </el-select>
          </div>
          <div
            class="photo-upload-section"
            :class="{
              'invalid-error': $v.thumbnail.$error
            }"
          >
            <h4>Расм</h4>
            <drop-file-input v-model="$v.thumbnail.$model" />
          </div>
          <div :style="{ display: 'flex' }">
            <el-button
              @click="saveResource"
              type="success"
              icon="el-icon-check"
              :disabled="isLoading"
              :style="{ flex: 1 }"
            >
              Сақлаш
            </el-button>
            <el-button
              @click="deleteResource"
              :disabled="isLoading"
              type="danger"
              icon="el-icon-delete"
              :style="{ flex: 1 }"
            >
              Ўчириш
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DropFileInput from "@/components/DropFileInput";
import { required } from "vuelidate/lib/validators";
import config from "@/config";

export default {
  components: {
    DropFileInput
  },
  data: () => ({
    selectedHashtags: [],
    isError: false,
    isLoading: false,
    hashtags: [
      {
        value: "HTML",
        label: "HTML"
      },
      {
        value: "CSS",
        label: "CSS"
      },
      {
        value: "JavaScript",
        label: "JavaScript"
      }
    ],
    form: {
      title_uz: "",
      title_ru: "",
      title_oz: "",
      description_uz: "",
      description_ru: "",
      description_oz: "",
      content_uz: "",
      content_ru: "",
      content_oz: "",
      publish_at: "",
      category: null,
      status: "draft"
    },
    selectedLang: "oz",
    thumbnail: null,
    langLoading: false
  }),
  validations: {
    form: {
      title_uz: { required },
      title_ru: { required },
      title_oz: { required },
      publish_at: { required },
      category: { required }
    },
    thumbnail: { required }
  },
  computed: {
    categories() {
      return this.$store.state.categoryModule.categories;
    }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    Promise.all([
      this.$store.dispatch("fetchSingleResource", this.$route.params.id),
      this.$store.dispatch("fetchCategories")
    ])
      .then(([singleResource]) => {
        this.form = { ...singleResource };
        this.form.category = singleResource.category.id;
        this.thumbnail = config.baseUrl + singleResource.thumbnail.url;
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        loading.close();
      });
  },
  watch: {
    selectedLang() {
      this.langLoading = true;
      setTimeout(() => {
        this.langLoading = false;
      }, 500);
    }
  },
  methods: {
    saveResource() {
      if (this.isLoading) return;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify.error({
          title: "Диққат!",
          message: "Формани тўлдиринг!"
        });

        return false;
      } else {
        this.isLoading = true;
        const loading = this.$loading({
          lock: true
        });
        const formData = new FormData();
        if (+new Date(this.form.publish_at) <= +new Date()) {
          this.form.status = "published";
        }
        formData.append("data", JSON.stringify(this.form));
        if (typeof this.thumbnail !== "string" && this.thumbnail !== null) {
          formData.append("files.thumbnail", this.thumbnail);
        }
        this.$store
          .dispatch("updateResource", {
            data: formData,
            id: this.$route.params.id
          })
          .then(() => {
            this.$message({
              message: "Ресурс сақланди!",
              type: "success"
            });
            this.$router.push("/resources");
          })
          .finally(() => {
            this.isLoading = false;
            loading.close();
          });
      }
    },
    deleteResource() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          const loading = this.$loading({
            lock: true
          });
          this.$store
            .dispatch("deleteResource", this.$route.params.id)
            .then(() => {
              this.$router.push("/resources");
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => {
              this.isLoading = false;
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title-section,
.content-section,
.desc-section,
.hashtag-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
}

.resource-content-card {
  position: relative;
  .el-card__header {
    position: sticky;
    top: 0;
  }
}

.lang-section h3 {
  margin-bottom: 10px;
}

.new-resource {
  padding: 20px;
}

// .editor {
//   height: 400px;
//   display: flex;
//   flex-direction: column;
// }

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.category-section,
.photo-upload-section,
.date-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 10px;
  }
}

.desc-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-input {
  width: 100%;
}
</style>
